import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/seo"
import styles from "./blog.module.scss"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      createdAt(formatString: "MMMM Do, YYYY")
      body {
        json
        fields {
            readingTime {
              minutes
            }
        }
      }
      abstract {
        abstract
      }
      tags: tag
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  return (
    <Layout>
      <SEO
        title={props.data.contentfulBlogPost.title}
        description={props.data.contentfulBlogPost.abstract.abstract}
        pathname={props.data.contentfulBlogPost.slug}
        true
      />
      <h1 className={styles.blogTitle}>
        {props.data.contentfulBlogPost.title}
      </h1>
      <p className={styles.blogAbstract}>
        {props.data.contentfulBlogPost.abstract.abstract}
      </p>
      <span className={styles.blogDate}>
        {props.data.contentfulBlogPost.createdAt}
        {' • '}
        {`${Math.round(props.data.contentfulBlogPost.body.fields.readingTime.minutes)} min read`}
      </span>
      <div>
      {props.data.contentfulBlogPost.tags.map(tag => {
        return (
          <span className={styles.postTag}>
            <small>{tag}</small>{" "}
          </span>
        )
      })}
      </div>
      <div className={styles.blogSeparator}></div>
      {documentToReactComponents(
        props.data.contentfulBlogPost.body.json,
        options
      )}
    </Layout>
  )
}

export default Blog
